@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font,ornament-font from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 44px;
  margin-top: 16px;
  background: #131313;
  padding: 48px 16px;
  color: white;

  @media desktop-s {
    margin-top: 28px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 20px;
}

.header {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  font-family: main-font;
  text-align: center;

  @media desktop-s {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.36;
  }
}

.subHeader {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px; /* 140% */
  font-family: main-font;
  letter-spacing: 0.2px;
  text-align: center;

  @media desktop-s {
    font-size: 24px;
    line-height: 40px;
  }
}

.decoratedText {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 28px;
  font-family: ornament-font;

  @media desktop-s {
    font-size: 32px;
    line-height: 44px;
  }
}

.button.button {
  padding: 8px 24px;
  width: auto;
  height: auto;
}
